<template>
  <picture>
    <source
      v-for="source in sources.filter((e) => e.mediaQuery)"
      :key="source?.url"
      :srcset="source?.url"
      :media="source.mediaQuery"
      :width="source.transform?.width"
      :height="source.transform?.height"
    />
    <img
      :src="sources[sources.length - 1]?.url"
      :width="sources[sources.length - 1].transform?.width"
      :height="sources[sources.length - 1].transform?.height"
    />
  </picture>
</template>

<script setup lang="ts">
import { useCloudinary } from '~/composables/cloudinary';
import { breakpointOrder, getMediaQueryForBreakpoint } from '~/configs/breakpoints';
import { getFirstIndex } from '~/util/arrays';

const props = defineProps({
  sourceSet: {
    type: Array as () => SourceSet[],
    default: () => [],
  },
});

const cloudinary = useCloudinary();
const sources = computed(() =>
  (props.sourceSet || [])
    .map((source) => ({
      ...source,
      url: cloudinary.generateImageUrl(getFirstIndex(source.cloudinary_asset)?.public_id, source.transform),
      mediaQuery: getMediaQueryForBreakpoint(source?.breakpoint),
      transform: source.transform,
    }))
    .sort((a, b) => {
      return breakpointOrder[b.breakpoint || 'none'] - breakpointOrder[a.breakpoint || 'none'];
    })
);
</script>

<style scoped>
picture,
img {
  display: inline-block;
  max-width: 100%;
  object-fit: cover;
  height: auto;
}
</style>
